import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MaxLength } from 'common-typescript/src/validationConstants';
import {
    CustomStudyDraft,
    ExternalAttainedStudy,
    OtmId,
    PriorCompetence,
    PriorLearning,
    PriorStudies,
} from 'common-typescript/types';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { maxLength, required } from 'sis-components/form/form-validators';
import { getLocalDateEditorValue, getLocalDateRangeEditorValue, subtractDayFromEndDate } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { PRIOR_LEARNING_TYPE } from 'sis-components/model/student-application-constants';
import { ExternalAttainedStudyService } from 'sis-components/service/external-attained-study.service';

import { AddPriorLearningModalComponent } from '../add-prior-learning-modal/add-prior-learning-modal.component';

@Component({
    selector: 'app-prior-learning-and-attachments-edit',
    templateUrl: './prior-learning-and-attachments-edit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningAndAttachmentsEditComponent implements OnInit {

    /** Determines the naming of the prior learning entries (depending on the type of the application). */
    @Input() type: 'INCLUDE' | 'SUBSTITUTE';
    @Input() customStudyDraft?: CustomStudyDraft;
    /** Initializes the form with the given prior learnings. */
    @Input() initialPriorLearnings?: PriorLearning[] = [];
    @Input() initialAttachments?: FileItem[] = [];
    @Input() workflowId?: OtmId;
    @Input() hidePreviousButton: boolean;
    @Output() attachmentsChange = new EventEmitter<FileItem[]>();
    @Output() exit = new EventEmitter<void>();
    @Output() previous = new EventEmitter<void>();
    @Output() continue = new EventEmitter<{ priorLearnings: PriorLearning[] }>();

    form: FormGroup;
    externalAttainedStudies$: Observable<ExternalAttainedStudy[]>;

    readonly acceptedFileTypes = [
        'application/msword',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'text/plain',
    ];

    constructor(
        private alertsService: AlertsService,
        private confirmDialogService: ConfirmDialogService,
        private fb: SisFormBuilder,
        private modalService: ModalService,
        private translate: TranslateService,
        private appErrorHandler: AppErrorHandler,
        private externalAttainedStudyService: ExternalAttainedStudyService,
    ) {
        this.addPriorLearningControls = this.addPriorLearningControls.bind(this);
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            priorLearnings: this.fb.array(this.initialPriorLearnings.map(pl => this.initializeFormGroupFromPriorLearning(pl)), required()),
        });

        this.externalAttainedStudies$ = this.externalAttainedStudyService.getExternalAttainedStudies()
            .pipe(
                take(1),
                tap((externalAttainedStudies: ExternalAttainedStudy[]) => externalAttainedStudies),
                this.appErrorHandler.defaultErrorHandler());
    }

    addPriorLearning(): void {
        this.modalService.open(AddPriorLearningModalComponent, null, { size: 'sm' })
            .closed.subscribe(this.addPriorLearningControls);
    }

    createPriorLearningForm(type: PRIOR_LEARNING_TYPE): FormGroup {
        const commonControls = {
            type: [type],
            externalAttainedStudyId: this.fb.control(null),
            attainmentLanguage: [null, [required()]],
            description: [
                null,
                [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)],
            ],
            name: [
                null,
                [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)],
            ],
            organisation: [
                null,
                [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)],
            ],
        };
        if (type === PRIOR_LEARNING_TYPE.COMPETENCE) {
            return this.fb.group({
                attainmentPeriod: this.fb.localDateRange(null, { required: true }),
                ...commonControls,
            });
        } if (type === PRIOR_LEARNING_TYPE.STUDIES) {
            return this.fb.group({
                attainmentDate: this.fb.localDate(null, { required: true }),
                code: [null, [maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]],
                credits: [
                    null,
                    [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)],
                ],
                grade: [null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]],
                gradeScale: [null, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]],
                ...commonControls,
            });
        }
        return null;
    }

    initializeFormGroupFromPriorLearning(priorLearning: PriorLearning): FormGroup {
        const priorLearningForm = this.createPriorLearningForm(priorLearning.type as PRIOR_LEARNING_TYPE);
        if (priorLearning.type === 'STUDIES') {
            const priorStudies = priorLearning as PriorStudies;
            priorLearningForm.patchValue({
                ...priorStudies,
                attainmentDate: this.fb.formatLocalDate(priorStudies.attainmentDate),
            });
        }
        if (priorLearning.type === 'COMPETENCE') {
            const priorCompetence = priorLearning as PriorCompetence;
            priorLearningForm.patchValue({
                ...priorCompetence,
                attainmentPeriod: {
                    startDate: this.fb.formatLocalDate(priorCompetence.attainmentPeriod.startDate),
                    endDate: this.fb.formatLocalDate(subtractDayFromEndDate(priorCompetence.attainmentPeriod.endDate)),
                },
            });
        }
        return priorLearningForm;
    }

    initializeFormGroupFromCustomStudyDraft(customStudyDraft: CustomStudyDraft, type: PRIOR_LEARNING_TYPE): FormGroup {
        const priorLearningForm = this.createPriorLearningForm(type);
        priorLearningForm.patchValue({
            description: this.customStudyDraft.description,
            name: this.customStudyDraft.name,
            organisation: this.customStudyDraft.location,
        });
        if (type === 'STUDIES') {
            priorLearningForm.patchValue({
                credits: `${this.customStudyDraft.credits ?? 0} ${this.translate.instant('CREDITS')}`,
            });
        }
        return priorLearningForm;
    }

    addPriorLearningControls(type: PRIOR_LEARNING_TYPE): void {
        // If creating the application based on a custom study draft, init the first prior learning entry with the data from the draft
        if (this.customStudyDraft && this.priorLearnings.length === 0) {
            this.priorLearnings.push(this.initializeFormGroupFromCustomStudyDraft(this.customStudyDraft, type));
        } else {
            this.priorLearnings.push(this.createPriorLearningForm(type));
        }
    }

    getPriorLearningEditorTitle(control: AbstractControl, index: number): string {
        const prefix = this.priorLearnings.length > 1 ? `${index + 1}. ` : '';
        const title = this.translate.instant(`STUDENT_APPLICATIONS.PRIOR_LEARNING.${this.type}_${control?.get('type')?.value}.TITLE_EDIT`);
        return `${prefix}${title}`;
    }

    isPriorCompetenceFormGroup(control: AbstractControl): boolean {
        return control?.get('type')?.value === PRIOR_LEARNING_TYPE.COMPETENCE;
    }

    isPriorStudiesFormGroup(control: AbstractControl): boolean {
        return control?.get('type')?.value === PRIOR_LEARNING_TYPE.STUDIES;
    }

    onDeletePriorLearning(index: number): void {
        if (index >= 0 && index < this.priorLearnings.length) {
            if (this.priorLearnings.at(index)?.pristine) {
                this.priorLearnings.removeAt(index);
            } else {
                this.confirmDialogService.confirm({
                    title: 'STUDENT_APPLICATIONS.PRIOR_LEARNING.REMOVE_PRIOR_LEARNING_TITLE',
                    description: 'STUDENT_APPLICATIONS.PRIOR_LEARNING.REMOVE_PRIOR_LEARNING_DESCRIPTION',
                })
                    .then(() => this.priorLearnings.removeAt(index))
                    .catch(() => {});
            }
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.continue.emit(this.mapFormValueToDataModel());
        } else if (this.priorLearnings.length === 0) {
            this.alertsService.addAlert({
                type: AlertType.DANGER,
                message: this.translate.instant('PROFILE.APPLICATIONS.PRIOR_LEARNING.PRIOR_LEARNING_REQUIRED'),
            });
        } else {
            this.form.markAllAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    get priorLearnings(): FormArray {
        return this.form.get('priorLearnings') as FormArray;
    }

    private mapFormValueToDataModel(): { priorLearnings: PriorLearning[] } {
        const priorLearnings = this.priorLearnings.controls.map((formGroup: FormGroup) =>
            this.isPriorStudiesFormGroup(formGroup) ?
                { ...formGroup.value, attainmentDate: getLocalDateEditorValue(formGroup.get('attainmentDate') as FormControl) } :
                { ...formGroup.value, attainmentPeriod: getLocalDateRangeEditorValue(formGroup.get('attainmentPeriod') as FormGroup) });
        return { priorLearnings };
    }
}
