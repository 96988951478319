import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import { dateUtils } from 'common-typescript/src/dateUtils';
import {
    Code,
    CooperationNetwork,
    CourseUnitResultItem,
    Organisation,
    OtmId,
    SearchFilterType,
    SearchParameterOption,
    SearchResult,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, forkJoin, from, Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { singleConcurrentSearchWithThrottle } from 'sis-common/search/search-utils';
import {
    COURSE_UNIT_SERVICE,
    SEARCH_PARAMETER_STORAGE_SERVICE,
    SEARCH_PARAMETERS,
} from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { silentErrorHandler } from 'sis-components/error-handler/silent-error-handler';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { CommonCodeService, IndexedCodes } from 'sis-components/service/common-code.service';
import { CooperationNetworkEntityService } from 'sis-components/service/cooperation-network-entity.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { ORGANISATION_JS_DATA_MODEL } from '../../ajs-upgraded-modules';
import { CourseCartEntityService } from '../../common/service/course-cart-entity.service';
import { SEARCH_CONSTANTS, SearchService } from '../search-service';

@Component({
    selector: 'app-search-cooperation-network',
    templateUrl: './search-cooperation-network.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [SearchService],
})
export class SearchCooperationNetworkComponent implements OnInit, OnDestroy {

    searchResult: SearchResult<CourseUnitResultItem>;
    university: Organisation;
    searchParameters = new this.SearchParameters({
        documentState: undefined,
        ignoreValidityPeriod: undefined,
        validity: 'ONGOING_AND_FUTURE',
    });

    input: string;
    noResultsMessage: string;

    currentPage = 1;
    initialQuerySort: any;

    supportedAttainmentLangs: Code[] = [];
    courseCartCourseUnitIds: OtmId[];

    cooperationNetworkUniversities: Organisation[] = [];
    cooperationNetworks: CooperationNetwork[] = [];

    searchParams: any = {};

    tooManyResults: boolean;
    destroyed$ = new Subject();
    rerunSearch$ = new BehaviorSubject(null);

    readonly languageUrn = 'urn:code:language';

    searchParameterOptions: { [name: string]: SearchParameterOption } = {};
    loading = true;
    searching = false;
    isMobileView: boolean;

    activeFilters = 0;

    searchSortOptions: Option[] = [
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.MATCH'), value: null },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_ASC'), value: 'name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_DESC'), value: '-name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_ASC'), value: 'credits' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_DESC'), value: '-credits' },
    ];

    constructor(
        @Inject(COURSE_UNIT_SERVICE) private courseUnitService: any,
        @Inject(SEARCH_PARAMETERS) private SearchParameters: any,
        private authService: AuthService,
        @Inject(SEARCH_PARAMETER_STORAGE_SERVICE) private searchParameterStorageService: any,
        @Inject(ORGANISATION_JS_DATA_MODEL) private organisationJsDataModel: any,
        private uiRouterGlobals: UIRouterGlobals,
        private cooperationNetworkEntityService: CooperationNetworkEntityService,
        private organisationEntityService: OrganisationEntityService,
        private localeService: LocaleService,
        private translate: TranslateService,
        private commonCodeService: CommonCodeService,
        private universityService: UniversityService,
        private breakpointService: BreakpointService,
        private searchService: SearchService,
        private appErrorHandler: AppErrorHandler,
        private courseCartEntityService: CourseCartEntityService,
    ) {
    }

    ngOnInit(): void {
        this.searchParameterStorageService.loadSearchParameterFromStorage(this.uiRouterGlobals.current.name, this.searchParameters);
        this.searchParameters.start = 0;
        this.searchParameters.limit = SEARCH_CONSTANTS.messagesPerPage;
        this.initialQuerySort = _.get(this.searchParameters, 'sort.value') || null;

        if (this.isLoggedIn()) {
            this.courseCartEntityService.getCourseCart(true)
                .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
                .subscribe((ids) => this.courseCartCourseUnitIds = ids);
        }

        this.rerunSearch$
            .pipe(
                takeUntil(this.destroyed$),
                singleConcurrentSearchWithThrottle((params: any) => this.searchRequest(params)),
            )
            .subscribe((searchResult: SearchResult<CourseUnitResultItem>) => {
                this.searching = false;
                if (searchResult) {
                    this.searchResult = searchResult;
                    this.tooManyResults = this.hasTooManyResults(searchResult);
                    this.messageOnNoResults();

                    if (searchResult.start === 0) {
                        this.currentPage = 1;
                    }
                } else {
                    this.searchResult = undefined;
                }
            });

        forkJoin([
            this.initUniversity(),
            this.initCooperationNetworks(),
            this.initCooperationNetworkOrganisations(),
            this.initSupportedAttainmentLangs(),
        ]).pipe(tap(() => this.initSearchParameters()))
            .subscribe(() => {
                this.loading = false;
                this.search();
            });

        this.breakpointService.breakpoint$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(breakpoint => this.isMobileView = (breakpoint < Breakpoint.SM));
    }

    initSearchParameters() {
        this.populateSearchParameterOptions();
        this.initSearchParams();
        this.getActiveFiltersCount();
    }

    initCooperationNetworkOrganisations() {
        return this.organisationEntityService.getRootOrganisations()
            .pipe(take(1), tap((organisations: Organisation[]) => {
                this.cooperationNetworkUniversities = organisations.filter((organisation: Organisation) => organisation.id !== this.universityService.getCurrentUniversityOrgId());
            }), this.appErrorHandler.defaultErrorHandler());
    }

    private initSearchParams() {
        const filterKeys = this.getFilterKeys();

        filterKeys.forEach((key: any) => {
            const searchParameterOptions = this.searchParameterOptions[key];
            const searchParameters = this.searchParameters[key];

            this.searchParams[key] = {
                options: this.searchService.mapSearchOptions(searchParameterOptions),
                selected: this.searchService.mapSelectedOptions(searchParameterOptions, searchParameters),
            };
        });
    }

    curTeachingLanguageUrnsChange(selected: any[]) {
        this.searchParameters.curTeachingLanguageUrns.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.curTeachingLanguageUrns, selected);
        this.getActiveFiltersCount();
        this.search();
    }

    getLabel(parameter: SearchParameterOption) {
        return this.translate.instant(`SEARCH.FILTER_TAGS.${parameter.name}`);
    }

    getFilterTitleLabel() {
        return this.searchService.getFilterTitleLabel(this.activeFilters);
    }

    removeFilters() {
        const keys = this.getFilterKeys();

        keys.forEach((key: string) => {
            this.searchParameters[key].value = [];
        });

        this.initSearchParams();
        this.getActiveFiltersCount();
        this.clearInputFields();
        this.searchResult = undefined;
        this.noResultsMessage = undefined;
        this.search();
    }

    private clearInputFields() {
        const filterItems = document.getElementsByClassName('search-filter-item');
        _.forEach(filterItems, (item) => {
            const inputField = item.querySelector('input');
            inputField.value = '';
        });
    }

    private getFilterKeys() {
        const keys: any = [];
        _.forOwn(this.searchParameterOptions, (value, key) => {
            if (!value.hide) {
                keys.push(key);
            }
        });
        return keys;
    }

    private getActiveFiltersCount() {
        this.activeFilters = 0;
        const keys = this.getFilterKeys();

        keys.forEach((key: string) => {
            if (this.searchParameters[key]) {
                this.activeFilters += this.searchParameters[key].value.length;
            }
        });
    }

    ngOnDestroy() {
        this.destroyed$.unsubscribe();
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }

    populateSearchParameterOptions() {
        this.searchParameterOptions = {
            searchString: { name: 'SEARCHSTRING', type: SearchFilterType.NO_POPOVER, hide: true },
            cooperationNetworkUniversityOrgId: {
                name: 'COOPERATION_NETWORK_UNIVERSITY',
                options: this.cooperationNetworkUniversities,
                type: SearchFilterType.MULTI,
            },
            cooperationNetworks: {
                name: 'COOPERATION_NETWORK',
                options: this.cooperationNetworks,
                type: SearchFilterType.MULTI,
            },
            curTeachingLanguageUrns: {
                name: 'TEACHINGLANGUAGE',
                options: this.supportedAttainmentLangs,
                type: SearchFilterType.MULTI,
            },
        };
    }

    initSupportedAttainmentLangs() {
        return forkJoin([
            this.commonCodeService.getCodebookObservable(this.languageUrn),
            from(convertAJSPromiseToNative(this.commonCodeService.getCodeBookUniversityUsage(this.languageUrn))),
        ]).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            tap((languages: [IndexedCodes, string[]]) => {
                const allFilters: Code[] = Object.values(languages[0]);
                const primaryFilters: Code[] = _.map(languages[1], urn => _.find(allFilters, { urn }))
                    .sort((a, b) => this.sortLanguages(a, b));
                const secondaryFilters: Code[] = _(allFilters)
                    .differenceBy(primaryFilters, 'urn')
                    .sort((a, b) => this.sortLanguages(a, b))
                    .value();
                this.supportedAttainmentLangs = primaryFilters.concat(secondaryFilters);
            }),
        );
    }

    private sortLanguages(a: Code, b: Code) {
        return this.localeService.localize(a.name).localeCompare(this.localeService.localize(b.name));
    }

    sort(sortValue: any) {
        this.searchParameters.sort.toggleValue(sortValue);
        this.search();
    }

    addCourseUnitToCourseCart(courseUnitId: string) {
        this.courseCartEntityService.addCurToCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    removeCourseUnitFromCourseCart(courseUnitId: string) {
        this.courseCartEntityService.deleteCurFromCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    initUniversity() {
        const organisationId: string = this.universityService.getCurrentUniversityOrgId();

        return this.organisationEntityService.findUniversityRootOrganisation(organisationId)
            .pipe(take(1), tap((organisation: Organisation) => this.setUniversity(organisation)), this.appErrorHandler.defaultErrorHandler());
    }

    initCooperationNetworks() {
        return this.cooperationNetworkEntityService.getByUniversityOrgId(this.universityService.getCurrentUniversityOrgId())
            .pipe(take(1), tap((cooperationNetworks: CooperationNetwork[]) => this.setCooperationNetWorks(cooperationNetworks)), this.appErrorHandler.defaultErrorHandler());
    }

    setCooperationNetWorks(cooperationNetworks: CooperationNetwork[]) {
        this.cooperationNetworks = cooperationNetworks.filter(cooperationNetwork =>
            dateUtils.dateRangeContains(moment(), cooperationNetwork.validityPeriod.startDateTime, cooperationNetwork.validityPeriod.endDateTime));
    }

    setUniversity(university: Organisation) {
        this.university = university;
        this.searchParameters.excludeUniversityOrgId.value = [{ id: university.id, name: university.name }];
        this.searchParameters.cooperationNetworkUniversities.value = [{ id: university.id, name: university.name }];
    }

    cooperationNetworksChange(selected: any[]) {
        this.searchParameters.cooperationNetworks.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.cooperationNetworks, selected);
        this.getActiveFiltersCount();
        this.search();
    }

    cooperationNetworkUniversityChange(selected: any[]) {
        this.searchParameters.cooperationNetworkUniversityOrgId.value = this.searchService.mapSelectedValuesToSearchParameters(this.searchParameters.cooperationNetworkUniversityOrgId, selected);
        this.getActiveFiltersCount();
        this.search();
    }

    onFullTextSearch(query: string) {
        this.input = query.length === 0 ? null : query;
        this.search(0, true);
    }

    hasTooManyResults(res: SearchResult<CourseUnitResultItem>): boolean {
        return !!_.get(res, 'truncated', false);
    }

    onPaginationChange() {
        const start: number = (this.currentPage - 1) * SEARCH_CONSTANTS.messagesPerPage;
        this.search(start);
        const target = document.getElementById('results-show-guide');
        target?.focus();
    }

    searchRequest(searchParams: any): Observable<unknown> {
        return from(convertAJSPromiseToNative(this.courseUnitService.searchActive(searchParams))).pipe(silentErrorHandler);
    }

    search(start?: number, onSubmit?: boolean) {
        this.searchParameters.searchString.value = this.input !== undefined ? this.input : this.searchParameters.searchString.value;

        if (this.isValidSearchString(onSubmit) && this.isValidQuery()) {
            // Determines which pages courseUnits will be fetched for the view, when "messagesPerPage" -variable is set.
            this.searchParameters.start = start ? start : 0;
            this.searching = true;
            this.rerunSearch$.next(this.searchParameters);
        } else {
            this.searchResult = undefined;
        }

        this.searchParameterStorageService.saveSearchParameterToStorage(this.uiRouterGlobals.current.name, this.searchParameters);
    }

    isValidQuery() {
        return this.searchParameters.isValid()
            && (this.searchParameters.cooperationNetworks.value.length > 0
                || this.searchParameters.cooperationNetworkUniversityOrgId.value.length > 0
                || this.searchParameters.curTeachingLanguageUrns.value.length > 0
                || (this.searchParameters.searchString.value !== null && this.searchParameters.searchString.value !== ''));
    }

    isValidSearchString(onSubmit?: boolean) {
        this.noResultsMessage = undefined;

        if (this.input && SEARCH_CONSTANTS.queryTextMinLength - this.input.length > 1) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_SHORT_PLURAL', { chars: SEARCH_CONSTANTS.queryTextMinLength - this.searchParameters.searchString.value.length });
        } else if (this.input && SEARCH_CONSTANTS.queryTextMinLength - this.input.length === 1) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_SHORT');
        } else if (this.input && this.input.length > SEARCH_CONSTANTS.queryTextMaxLength) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_LONG');
        } else if (onSubmit && this.input === null && !this.isValidQuery()) {
            this.noResultsMessage = this.translate.instant('SEARCH_QUERY_TEXT_TOO_SHORT_PLURAL', { chars: SEARCH_CONSTANTS.queryTextMinLength });
        }
        return !this.noResultsMessage;
    }

    get messagesPerPage() {
        return SEARCH_CONSTANTS.messagesPerPage;
    }

    get maxSize() {
        return SEARCH_CONSTANTS.maxSize;
    }

    messageOnNoResults() {
        this.noResultsMessage = undefined;

        if (this.searchResult?.total === 0) {
            this.noResultsMessage = this.translate.instant('SEARCH_NO_RESULTS');
        }

        if (this.tooManyResults) {
            this.noResultsMessage = this.translate.instant('SEARCH_LARGE_RESULT');
        }
    }
}

