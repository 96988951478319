<ng-container *transloco="let t">
  <ng-container *ngIf="priorLearningWorkflow.type === studentWorkflowType.PRIOR_LEARNING_INCLUSION_WORKFLOW">
    <div class="sis-mb-sm d-block" *ngIf="$any(priorLearningWorkflow)?.degreeProgrammeId | module | async as degreeProgramme">
      <transloco key="PROFILE.APPLICATIONS.PRIOR_LEARNING.APPLICATION_INFO_INCLUSION"
                 [params]="{degreeProgrammeName: degreeProgramme.name | localizedString, attainmentName: $any(priorLearningWorkflow)?.name}">
      </transloco>
    </div>
  </ng-container>
  <ng-container *ngIf="priorLearningWorkflow.type === studentWorkflowType.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW">
    <div class="sis-mb-sm d-block" *ngIf="$any(priorLearningWorkflow)?.courseUnitId | courseUnit | async as courseUnit">
      <transloco key="PROFILE.APPLICATIONS.PRIOR_LEARNING.APPLICATION_INFO_SUBSTITUTION"
                 [params]="{courseUnitName: courseUnit.name | localizedString}">
      </transloco>
    </div>
  </ng-container>
  <sis-prior-learning-workflow-summary [workflow]="priorLearningWorkflow"
                                       (openCourseUnitInfoModal)="openCourseUnitInfoModal.emit()">
  </sis-prior-learning-workflow-summary>
  <sis-prior-learning-list [applicationType]="priorLearningWorkflow.type"
                           [priorLearnings]="priorLearningWorkflow.priorLearnings">
  </sis-prior-learning-list>
  <sis-application-files [editable]="priorLearningWorkflow.state === studentWorkflowState.REQUESTED"
                         [applicationId]="priorLearningWorkflow.id"
                         [studentId]="priorLearningWorkflow.studentId">
  </sis-application-files>
  <fudis-heading [level]="3">{{t('STUDENT_APPLICATIONS.DECISION')}}</fudis-heading>
  <transloco key="{{'PROFILE.APPLICATIONS.' + (priorLearningWorkflow.mustPrintDecision ? 'DECISION_REQUESTED_BY_MAIL' : 'DECISION_NOT_REQUESTED_BY_MAIL')}}" class="decision-description"/>
</ng-container>
