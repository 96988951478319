import { Injectable } from '@angular/core';
import {
    CalendarApi,
    CalendarOptions,
    DatesSetArg,
    DayHeaderContentArg,
    EventSourceFuncArg,
} from '@fullcalendar/core';
import { VerboseFormattingArg } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { dateUtils } from 'common-typescript';
import {
    Enrolment, FullCalendarCustomEvent, FullCalendarStudyEvent,
} from 'common-typescript/types';
import moment from 'moment';
import 'moment/locale/sv';
import { firstValueFrom, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { Breakpoint } from 'sis-components/service/breakpoint.service';

import { FullCalendarOwnEventService } from './full-calendar-own-event.service';
import { FullCalendarStudyEventService } from './full-calendar-study-event.service';
import { FullCalendarTranslationService } from './full-calendar-translation.service';
import { FullCalendarWebStorageService } from './full-calendar-web-storage.service';
import { FullCalendarViewType } from './full-calendar.types';

@Injectable({
    providedIn: 'root',
})
export class FullCalendarService {

    constructor(
        private localeService: LocaleService,
        private fullCalendarWebStorageService: FullCalendarWebStorageService,
        private fullCalendarTranslationService: FullCalendarTranslationService,
        private fullCalendarOwnEventService: FullCalendarOwnEventService,
        private fullCalendarStudyEventService: FullCalendarStudyEventService,
    ) { }

    getOptions(enrolments: Enrolment[], contentWidth: () => number, calendarApi: () => CalendarApi): Observable<CalendarOptions> {
        return this.fullCalendarTranslationService.translations$.pipe(
            take(1),
            map((translations: string[]) => this.buildCalendarOptions(translations, enrolments, contentWidth, calendarApi)));
    }

    buildCalendarOptions(translations: string[], enrolments: Enrolment[], contentWidth: () => number, calendarApi: () => CalendarApi): CalendarOptions {
        const [weekText, today, week, month, prev, next] = translations;
        return {
            plugins: [timeGridPlugin, dayGridPlugin],
            customButtons: {
                customTodayButton: {
                    text: today,
                    click() {
                        calendarApi().today();
                    },
                },
            },
            headerToolbar: {
                start: 'customTodayButton,prev,next,title',
                end: `${FullCalendarViewType.TIME_GRID_WEEK},${FullCalendarViewType.DAY_GRID_MONTH}`,
            },
            views: {
                [FullCalendarViewType.DAY_GRID_MONTH]: {
                    titleFormat: { year: 'numeric', month: 'long' },
                },
                [FullCalendarViewType.TIME_GRID_WEEK]: {
                    titleFormat: this.titleFormat,
                    allDaySlot: false,
                    slotEventOverlap: false,
                    slotLabelFormat: (arg: VerboseFormattingArg) => this.slotLabelFormat(arg),
                },
            },
            locales: [
                {
                    code: 'fi',
                    dayHeaderContent: (arg: DayHeaderContentArg) => this.fiDayHeaderContent(arg),
                },
                {
                    code: 'sv',
                    dayHeaderContent: (arg: DayHeaderContentArg) => this.svDayHeaderContent(arg),
                },
                {
                    code: 'en',
                    dayHeaderContent: (arg: DayHeaderContentArg) => this.enDayHeaderContent(arg),
                },
            ],
            buttonText: {
                today,
                month,
                week,
                prev: '', // chevron is added with CSS
                next: '', // chevron is added with CSS
            },
            buttonHints: {
                prev: (_buttonText, buttonName) => {
                    if (buttonName === 'month') {
                        return `${prev} ${month.toLowerCase()}`;
                    }
                    if (buttonName === 'week') {
                        return `${prev} ${week.toLowerCase()}`;
                    }
                    return prev;
                },
                next: (_buttonText, buttonName) => {
                    if (buttonName === 'month') {
                        return `${next} ${month.toLowerCase()}`;
                    }
                    if (buttonName === 'week') {
                        return `${next} ${week.toLowerCase()}`;
                    }
                    return next;
                },
            },
            buttonIcons: false,
            dayMaxEventRows: 5,
            eventMaxStack: 5,
            initialView: this.getInitialView(),
            initialDate: this.getInitialDate(),
            locale: this.localeService.getCurrentLanguage(),
            // Time config
            slotMinTime: '06:00:00',
            slotMaxTime: '30:00:00',
            // Weeks config
            weekText,
            fixedWeekCount: false,
            weekNumbers: true,
            firstDay: 1,
            eventSources: [
                {
                    id: 'studyEventSource',
                    events: (fetchInfo: EventSourceFuncArg) => firstValueFrom(this.fullCalendarStudyEventService.getEvents(fetchInfo, enrolments)).then((events: FullCalendarStudyEvent[]) => events),
                },
                {
                    id: 'ownCalendarEventSource',
                    events: (fetchInfo: EventSourceFuncArg) => firstValueFrom(this.fullCalendarOwnEventService.getEvents(fetchInfo)).then((events: FullCalendarCustomEvent[]) => events),
                },
            ],
            datesSet: (arg: DatesSetArg) => {
                this.persistCalendarState(arg);
            },
            windowResize: (arg) => this.setHeight(() => arg.view.calendar, contentWidth),
            eventDisplay: 'block',
            expandRows: true,
            height: this.getHeight(contentWidth()),
        };
    }

    getHeight(contentWidth: number): 'auto' | number {
        if (contentWidth < Breakpoint.MD) {
            return 'auto'; // overflows container, the whole page becomes scrollable
        }
        const headerContainer: HTMLElement = document.getElementsByClassName('app-full-calendar__header-and-calendar-container__header')[0] as HTMLElement;
        const topNav = document.querySelector('top-nav') as HTMLElement;
        const headerAndCalendarContainer = document.getElementsByClassName('app-full-calendar__header-and-calendar-container')[0] as HTMLElement;

        const headerHeight = headerContainer?.offsetHeight || 0;
        const topNavHeight = topNav?.offsetHeight || 0;

        const containerTopPaddingOffset = parseFloat(window.getComputedStyle(headerAndCalendarContainer)?.paddingTop) || 0;
        const containerBottomPaddingOffset = parseFloat(window.getComputedStyle(headerAndCalendarContainer)?.paddingBottom) || 0;

        const windowInnerHeight = window.innerHeight;

        return windowInnerHeight - headerHeight - topNavHeight - containerBottomPaddingOffset - containerTopPaddingOffset;
    }

    setHeight(calendar: () => CalendarApi, contentWidth: () => number) {
        calendar().setOption('height', this.getHeight(contentWidth()));
    }

    titleFormat(arg: VerboseFormattingArg) {
        const finnishDateTimeFormat = new Intl.DateTimeFormat('fi', { year: 'numeric', month: 'numeric', day: 'numeric' });
        return finnishDateTimeFormat.formatRange(arg.start.marker, arg.end.marker);
    }

    slotLabelFormat(arg: VerboseFormattingArg) {
        const m = dateUtils.createMoment(arg.date.marker);
        // FullCalendar sets the timezone as UTC in its native formatting function.
        // Unless set, the local timezone difference offset is applied to the slots.
        const utcMoment = m.utc();
        return utcMoment.format('HH.mm');
    }

    fiDayHeaderContent(arg: DayHeaderContentArg) {
        if (arg.view.type === FullCalendarViewType.TIME_GRID_WEEK) {
            return moment(arg.date).locale('fi').format('ddd D.M.');
        }
        if (arg.view.type === FullCalendarViewType.DAY_GRID_MONTH) {
            return arg.date.toLocaleDateString('fi', { weekday: 'long' });
        }
    }

    svDayHeaderContent(arg: DayHeaderContentArg) {
        if (arg.view.type === FullCalendarViewType.TIME_GRID_WEEK) {
            return moment(arg.date).locale('sv').format('ddd D.M.');
        }
        if (arg.view.type === FullCalendarViewType.DAY_GRID_MONTH) {
            return arg.date.toLocaleDateString('sv', { weekday: 'long' });
        }
    }

    enDayHeaderContent(arg: DayHeaderContentArg) {
        if (arg.view.type === FullCalendarViewType.TIME_GRID_WEEK) {
            return moment(arg.date).locale('en').format('ddd D.M.');
        }
        if (arg.view.type === FullCalendarViewType.DAY_GRID_MONTH) {
            return arg.date.toLocaleDateString('en', { weekday: 'long' });
        }
    }

    getInitialView(): string {
        return this.fullCalendarWebStorageService.getInitialView();
    }

    getInitialDate(): Date {
        return this.fullCalendarWebStorageService.getInitialDate();
    }

    persistCalendarState(arg: DatesSetArg) {
        this.fullCalendarWebStorageService.persistCalendarState(arg);
    }
}
