import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    AssessmentsRequest,
    CsvRequestEvent,
    CurriculumRequest,
    EnrolledStudentsRequest,
    MassExamSessionEnrolmentsRequest,
    MessageEventType,
    OtmId,
    PdfRequest,
    PdfRequestEvent,
    ResponseErrorEvent,
    ResponseEvent,
    StoredDocumentSignatureInfo,
    StudyCertificateRequest,
    StudyRightRecordTranscriptRequest,
    StudyYear,
    SyllabusRequest,
    TermRegistrationPeriod,
    TranscriptRequest,
} from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import { ignoreElements, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { AlertsService, AlertType } from '../alerts/alerts-ng.service';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { systemErrorModalOpener } from '../error-handler/system-error-modal/system-error-modal.component';
import { UniversityService } from '../service/university.service';
import { getNextStudyTermLocator, getStudyTermLocator } from '../study-terms/study-year-utils';

import { NotificationsService } from './notifications/notifications.service';
import { StudyYearsEntityService } from './study-years-entity.service';
import { TermRegistrationPeriodEntityService } from './term-registration-period-entity.service';

export type TranscriptRequestType =
    'TRANSCRIPT_STAFF' |
    'TRANSCRIPT_STUDENT' |
    'STUDY_RIGHT_RECORD_TRANSCRIPT_STAFF' |
    'STUDY_RIGHT_RECORD_TRANSCRIPT_STUDENT';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class PainoRequestService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.painoRequestService.downgraded',
        serviceName: 'painoRequestService',
    };

    openSystemErrorModal = systemErrorModalOpener();

    constructor(private appErrorHandler: AppErrorHandler,
                private alertService: AlertsService,
                @Inject(DOCUMENT) private document: Document,
                private http: HttpClient,
                private notificationsService: NotificationsService,
                private studyYearsEntityService: StudyYearsEntityService,
                private termRegistrationPeriodEntityService: TermRegistrationPeriodEntityService,
                private translocoService: TranslocoService,
                private universityService: UniversityService,
                private uuidService: UuidService) {
        this.registerFileDownloadListeners();
    }

    universityOrgId: string = this.universityService.getCurrentUniversityOrgId();
    university: string = _.first(_.split(this.universityOrgId, '-', 1));

    private showWaitAlert(ticketId: string, painoEvent: string = 'PDF_REQUEST'): Observable<never> {
        return this.translocoService.selectTranslate(painoEvent === 'CSV_REQUEST' ? 'CSV_EVENTS.WAIT.ALERT' : 'PDF_EVENTS.WAIT.ALERT')
            .pipe(
                take(1),
                tap(translatedMessage => this.alertService.addAlert({
                    type: AlertType.WARNING,
                    message: translatedMessage,
                    identifier: ticketId,
                })),
                ignoreElements(),
            );
    }

    private setSignerTitle(pdfRequest: PdfRequest,
                           signerTitlesByLanguage: { [key: string]: string },
                           language: string): PdfRequest {
        return { ...pdfRequest, ...{ signerTitle: signerTitlesByLanguage[language] } };
    }

    private createLanguageRequests(request: PdfRequest,
                                   selectedLanguages: { [locale: string]: boolean },
                                   signerTitlesByLanguage: { [key: string]: string } = null): PdfRequest[] {
        const pdfRequests: PdfRequest[] = [];
        _.forEach(selectedLanguages, (isSelected, language) => {
            if (isSelected) {
                let pdfRequest: PdfRequest = { ...request, ...{ selectedLanguage: language } };
                if (signerTitlesByLanguage !== null) {
                    pdfRequest = this.setSignerTitle(pdfRequest, signerTitlesByLanguage, language);
                }
                pdfRequests.push(pdfRequest);
            }
        });
        return pdfRequests;
    }

    private createLanguageRequestsForStudyRightRecordTranscript(request: TranscriptRequest,
                                                                selectedLanguages: { [locale: string]: boolean },
                                                                studyRightIds: OtmId[],
                                                                signerTitlesByLanguage: { [key: string]: string } = null): PdfRequest[] {
        const pdfRequests: PdfRequest[] = [];
        _.forEach(selectedLanguages, (isSelected, language) => {
            if (isSelected) {
                studyRightIds.forEach((id) => {
                    let pdfRequest: PdfRequest = {
                        ...request,
                        ...{ selectedLanguage: language, studyRightId: id },
                    } as StudyRightRecordTranscriptRequest;
                    if (signerTitlesByLanguage !== null) {
                        pdfRequest = this.setSignerTitle(pdfRequest, signerTitlesByLanguage, language);
                    }
                    pdfRequests.push(pdfRequest);
                });
            }
        });
        return pdfRequests;
    }

    sendTranscriptRequest(parameters: Partial<TranscriptRequest>,
                          selectedLanguages: { [locale: string]: boolean },
                          type: TranscriptRequestType,
                          studyRightIds: OtmId[] = null,
                          signerTitlesByLanguage: { [key: string]: string } = null): void {
        let url: string;
        if (type === 'TRANSCRIPT_STAFF') {
            url = '/ori/api/pdf-requests/transcript-staff';
        } else if (type === 'TRANSCRIPT_STUDENT') {
            url = '/ori/api/pdf-requests/transcript-student';
        } else if (type === 'STUDY_RIGHT_RECORD_TRANSCRIPT_STAFF') {
            url = '/ori/api/pdf-requests/study-right-record-transcript-staff';
        } else if (type === 'STUDY_RIGHT_RECORD_TRANSCRIPT_STUDENT') {
            url = '/ori/api/pdf-requests/study-right-record-transcript-student';
        } else {
            throw Error('Not supported request type');
        }

        const ticketId: string = this.uuidService.randomUUID();
        this.studyYearsEntityService.getCurrentStudyYear(this.universityOrgId)
            .pipe(
                map((studyYear: StudyYear) =>
                    this.createTranscriptRequest(
                        studyYear, parameters, selectedLanguages, ticketId, type, studyRightIds, signerTitlesByLanguage),
                ),
                switchMap(pdfRequestEvent => this.http.post(url, pdfRequestEvent)),
                switchMap(() => this.showWaitAlert(ticketId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    private createTranscriptRequest(currentStudyYear: StudyYear,
                                    parameters: Partial<TranscriptRequest>,
                                    selectedLanguages: { [locale: string]: boolean },
                                    ticketId: string,
                                    type: TranscriptRequestType,
                                    studyRightIds: OtmId[] = null,
                                    signerTitlesByLanguage: { [key: string]: string } = null): PdfRequestEvent {
        const currentDate = moment().toISOString();
        const currentStudyTerm = this.studyYearsEntityService.getCurrentStudyTermOfStudyYear(currentStudyYear);
        const studyTermIndex = this.studyYearsEntityService.getCurrentUniversityStudyTermIndex(currentStudyYear);
        const nextStudyTerm = getNextStudyTermLocator(currentDate);
        const pdfRequestBase: Partial<TranscriptRequest> = {
            pdfAMode: true,
            university: this.university,
            universityOrgId: this.universityOrgId,
            ticketId,
            studyTermIndex,
            nextStudyTermStartYear: nextStudyTerm.studyYearStartYear,
            nextTermIndex: nextStudyTerm.termIndex,
            studyTermStartYear: moment(currentStudyTerm.valid.startDate).year(),
            studyYearStartYear: currentStudyYear.startYear,
            type,
        };

        const pdfRequest: TranscriptRequest = { ...pdfRequestBase, ...parameters as TranscriptRequest };

        let requests: PdfRequest[];
        if (type === 'TRANSCRIPT_STAFF' || type === 'TRANSCRIPT_STUDENT') {
            requests = this.createLanguageRequests(
                pdfRequest,
                selectedLanguages,
                signerTitlesByLanguage,
            );
        } else if (type === 'STUDY_RIGHT_RECORD_TRANSCRIPT_STAFF' || type === 'STUDY_RIGHT_RECORD_TRANSCRIPT_STUDENT') {
            requests = this.createLanguageRequestsForStudyRightRecordTranscript(
                pdfRequest,
                selectedLanguages,
                studyRightIds,
                signerTitlesByLanguage,
            );
        } else {
            throw Error('Not supported request type');
        }
        return {
            ticketId,
            requests,
            eventType: 'PDF_REQUEST',
            requestUserId: null, // backend sets this
        };
    }

    /**
     * Returns observable that resolves undefined on success
     */
    sendDegreeCertificateWithAttachmentsRequest(printTypeParameters: any): Observable<never> {
        const ticketId: string = this.uuidService.randomUUID();
        return this.studyYearsEntityService.getCurrentStudyYear(this.universityOrgId)
            .pipe(
                map((studyYear: StudyYear) =>
                    this.createDegreeCertificateWithAttachmentsRequest(studyYear, ticketId, printTypeParameters),
                ),
                switchMap(pdfRequestEvent => this.http.post('/ori/api/pdf-requests/degree-certificate', pdfRequestEvent)),
                switchMap(() => this.showWaitAlert(ticketId)),
                take(1),
            );
    }

    /**
     * Returns observable that resolves undefined on success
     */
    sendSignableDegreeCertificateWithAttachmentsRequest(printTypeParameters: any): Observable<never> {
        const ticketId: string = this.uuidService.randomUUID();
        return this.studyYearsEntityService.getCurrentStudyYear(this.universityOrgId)
            .pipe(
                map((studyYear: StudyYear) =>
                    this.createDegreeCertificateWithAttachmentsRequest(studyYear, ticketId, printTypeParameters),
                ),
                switchMap(pdfRequestEvent => this.http.post('/ori/api/pdf-requests/degree-certificate-electronic-signature', pdfRequestEvent)),
                switchMap(() => this.showWaitAlert(ticketId)),
                take(1),
            );
    }

    private createDegreeCertificateWithAttachmentsRequest(currentStudyYear: StudyYear,
                                                          ticketId: string,
                                                          printTypeParameters: any): PdfRequestEvent {
        const currentStudyTerm = this.studyYearsEntityService.getCurrentStudyTermOfStudyYear(currentStudyYear);
        const studyTermIndex = this.studyYearsEntityService.getCurrentUniversityStudyTermIndex(currentStudyYear);

        const pdfRequestBase = {
            studyTermStartYear: moment(currentStudyTerm.valid.startDate).year(),
            studyYearStartYear: currentStudyYear.startYear,
            studyTermIndex,
            university: this.university,
            universityOrgId: this.universityOrgId,
            ticketId,
        };
        let requests: PdfRequest[] = [];

        _.forEach(printTypeParameters, (printType) => {
            // pdfAMode will be false if print is draft, because PDF/A-1b does not allow the use of transparency.
            const printSpecificBase = _.assign({}, _.assign(pdfRequestBase, { pdfAMode: !printType.draft }), printType);
            const morePdfRequests = this.composePdfRequestsForType(printSpecificBase, printType.languages, printType.type);
            requests = _.concat(requests, morePdfRequests);
        });

        return {
            ticketId,
            requests,
            eventType: 'PDF_REQUEST',
            requestUserId: null, // backend sets this
        };
    }

    private composePdfRequestsForType(pdfRequestBase: any,
                                      languages: { [locale: string]: boolean },
                                      type: string): PdfRequest[] {
        const pdfRequests: PdfRequest[] = [];
        _.forEach(languages, (languageSelected, language) => {
            if (languageSelected) {
                const pdfRequest = _.assign({}, pdfRequestBase, { selectedLanguage: language, type });
                this.processSignerTitleLanguages(pdfRequest, language);
                pdfRequests.push(pdfRequest);
            }
        });
        return pdfRequests;
    }

    private processSignerTitleLanguages(pdfRequest: any, selectedLanguage: string) {
        if (pdfRequest.signerTitle) {
            _.assign(pdfRequest, { signerTitle: pdfRequest.signerTitle[selectedLanguage] });
        }
        if (pdfRequest.signer2Title) {
            _.assign(pdfRequest, { signer2Title: pdfRequest.signer2Title[selectedLanguage] });
        }
        if (pdfRequest.translationSignerTitle) {
            _.assign(pdfRequest, { translationSignerTitle: pdfRequest.translationSignerTitle[selectedLanguage] });
        }
    }

    sendStudyCertificateRequest(parameters: Partial<StudyCertificateRequest>,
                                selectedLanguages: { [locale: string]: boolean },
                                signerTitlesByLanguage: { [key: string]: string } = null): void {
        const ticketId: string = this.uuidService.randomUUID();
        this.termRegistrationPeriodEntityService.getPeriods(this.universityOrgId)
            .pipe(
                map((termRegistrationPeriods: TermRegistrationPeriod[]) =>
                    this.createStudyCertificateRequest(termRegistrationPeriods, parameters, selectedLanguages, ticketId, signerTitlesByLanguage)),
                switchMap((pdfRequestEvent) => this.http.post('/ori/api/pdf-requests/study-certificate', pdfRequestEvent)),
                switchMap(() => this.showWaitAlert(ticketId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    private createStudyCertificateRequest(termRegistrationPeriods: TermRegistrationPeriod[],
                                          parameters: Partial<StudyCertificateRequest>,
                                          selectedLanguages: { [locale: string]: boolean },
                                          ticketId: string,
                                          signerTitlesByLanguage: { [key: string]: string } = null): PdfRequestEvent {
        const studyTerm = getStudyTermLocator();
        const nextStudyTerm = getNextStudyTermLocator();
        const nextTermRegistrationPeriod: TermRegistrationPeriod = termRegistrationPeriods.find(trp =>
            trp.term1.studyYearStartYear === nextStudyTerm.studyYearStartYear &&
            trp.term1.termIndex === nextStudyTerm.termIndex);

        const pdfRequestBase: Partial<StudyCertificateRequest> = {
            pdfAMode: true,
            ticketId,
            university: this.university,
            universityOrgId: this.universityOrgId,
            studyYearStartYear: studyTerm.studyYearStartYear,
            studyTermIndex: studyTerm.termIndex,
            nextStudyTermStartYear: nextStudyTerm.studyYearStartYear,
            nextTermIndex: nextStudyTerm.termIndex,
            termRegistrationPeriodStartDate: _.get(nextTermRegistrationPeriod, 'validityPeriod.startDate'),
        };
        const pdfRequest: PdfRequest = { ...pdfRequestBase, ...parameters as StudyCertificateRequest };
        return {
            ticketId,
            requests: this.createLanguageRequests(pdfRequest, selectedLanguages, signerTitlesByLanguage),
            eventType: 'PDF_REQUEST',
            requestUserId: null, // backend sets this
        };
    }

    sendCurriculumRequest(parameters: Partial<CurriculumRequest>[]): void {
        const ticketId: string = this.uuidService.randomUUID();
        const pdfRequestBase: Partial<CurriculumRequest> = {
            pdfAMode: false,
            ticketId,
            university: this.university,
            universityOrgId: this.universityOrgId,
            type: 'CURRICULUM',
        };

        const requests: PdfRequest[] = [];

        _.forEach(parameters, (printParameters) => {
            const curriculumRequest: CurriculumRequest = { ...printParameters, ...pdfRequestBase } as CurriculumRequest;
            requests.push(curriculumRequest);
        });

        const pdfRequestEvent: PdfRequestEvent = {
            ticketId,
            requests,
            eventType: 'PDF_REQUEST',
            requestUserId: null, // backend sets this
        };

        this.http.post('/ori/api/pdf-requests/curriculum', pdfRequestEvent)
            .pipe(
                switchMap(() => this.showWaitAlert(ticketId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    sendSyllabusRequest(parameters: Partial<SyllabusRequest>): void {
        const ticketId: string = this.uuidService.randomUUID();
        const pdfRequestBase: Partial<SyllabusRequest> = {
            pdfAMode: false,
            ticketId,
            university: this.university,
            universityOrgId: this.universityOrgId,
            type: 'SYLLABUS',
        };

        const pdfRequestEvent: PdfRequestEvent = {
            ticketId,
            requests: [{ ...parameters, ...pdfRequestBase } as SyllabusRequest],
            eventType: 'PDF_REQUEST',
            requestUserId: null, // backend sets this
        };

        this.http.post('/ori/api/pdf-requests/syllabus', pdfRequestEvent)
            .pipe(
                switchMap(() => this.showWaitAlert(ticketId)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    sendEnrolledStudentsCsvRequest(parameters: Partial<EnrolledStudentsRequest>): void {
        const ticketId: string = this.uuidService.randomUUID();
        const csvRequestBase: Partial<EnrolledStudentsRequest> = {
            ticketId,
            university: this.university,
            universityOrgId: this.universityOrgId,
            type: 'ENROLLED_STUDENTS',
        };

        const csvRequestEvent: CsvRequestEvent = {
            ticketId,
            requests: [{ ...parameters, ...csvRequestBase } as EnrolledStudentsRequest],
            eventType: 'CSV_REQUEST',
            requestUserId: null, // backend sets this
        };

        this.http.post('/ilmo/api/csv-requests/enrolled-students', csvRequestEvent)
            .pipe(
                switchMap(() => this.showWaitAlert(ticketId, csvRequestEvent.eventType)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    sendMassExamEnrolmentsCsvRequest(parameters: Partial<MassExamSessionEnrolmentsRequest>): void {
        const ticketId: string = this.uuidService.randomUUID();
        const csvRequestBase: Partial<MassExamSessionEnrolmentsRequest> = {
            ticketId,
            university: this.university,
            universityOrgId: this.universityOrgId,
            type: 'MASS_EXAM_SESSION_ENROLMENTS',
        };

        const csvRequestEvent: CsvRequestEvent = {
            ticketId,
            requests: [{ ...parameters, ...csvRequestBase } as MassExamSessionEnrolmentsRequest],
            eventType: 'CSV_REQUEST',
            requestUserId: null, // backend sets this
        };

        this.http.post('/ilmo/api/csv-requests/mass-exam-session-enrolments', csvRequestEvent)
            .pipe(
                switchMap(() => this.showWaitAlert(ticketId, csvRequestEvent.eventType)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    sendAssessmentsCsvRequest(parameters: Partial<AssessmentsRequest>): void {
        const ticketId: string = this.uuidService.randomUUID();
        const csvRequestBase: Partial<AssessmentsRequest> = {
            ticketId,
            university: this.university,
            universityOrgId: this.universityOrgId,
            type: 'ASSESSMENTS',
        };

        const csvRequestEvent: CsvRequestEvent = {
            ticketId,
            requests: [{ ...parameters, ...csvRequestBase } as AssessmentsRequest],
            eventType: 'CSV_REQUEST',
            requestUserId: null, // backend sets this
        };

        this.http.post('/paino/api/csv-requests/assessments', csvRequestEvent)
            .pipe(
                switchMap(() => this.showWaitAlert(ticketId, csvRequestEvent.eventType)),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    private registerFileDownloadListeners(): void {
        const messagesByEventType: { [type in MessageEventType]?: string } = {
            CSV_RESPONSE: 'CSV_EVENTS.READY.ALERT',
            CSV_ERROR: 'CSV_EVENTS.ERROR.STATUSTEXT',
            PDF_RESPONSE: 'PDF_EVENTS.READY.ALERT',
            PDF_ERROR: 'PDF_EVENTS.ERROR.STATUSTEXT',
        };

        this.notificationsService.getEventsObservable<ResponseEvent>('pdfResponseEvent')
            .subscribe(event => this.alertService.addAlert({
                type: AlertType.SUCCESS,
                message: this.translocoService.translate(messagesByEventType[event.eventType]),
                identifier: event.ticketId,
                onClickCallback: () => this.downloadFile(event.groupId),
            }));

        this.notificationsService.getEventsObservable<ResponseErrorEvent>('pdfResponseErrorEvent')
            .subscribe(event => {
                this.alertService.dismissAlertIfExists(event.ticketId);
                this.openSystemErrorModal({
                    title: this.translocoService.translate('ERROR.BACKEND.DEFAULT.TITLE'),
                    message: this.translocoService.translate(messagesByEventType[event.eventType]),
                    errorDetails: event.errorMessage,
                });
            });
    }

    private downloadFile(groupId: string): void {
        this.http.get<StoredDocumentSignatureInfo>(`/tasku/api/stored-document-groups/${groupId}/presigned-download`)
            .subscribe(response => this.document.location.href = `/tasku${response.urlPath}`);
    }
}
