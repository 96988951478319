import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    CompletionMethod,
    CourseUnit, CourseUnitAttainment,
    CourseUnitRealisation, CourseUnitSelection,
    Enrolment,
    GradeRaiseAttempt,
    OtmId,
    StudyPeriod,
    StudyYear,
} from 'common-typescript/types';
import _, { keyBy } from 'lodash';
import { BehaviorSubject, concatMap, defaultIfEmpty, forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { COMMON_STUDY_PERIOD_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { COURSE_UNIT_INFO_MODAL } from '../../../ajs-upgraded-modules';
import { enrolmentWizardModalOpener } from '../../enrolment-wizard/enrolment-wizard.component';
import {
    continueToEnrolmentModalOpener,
} from '../continue-to-enrolment-modal/continue-to-enrolment-modal.component';
import { CourseUnitTeaching, CourseUnitTeachingService } from '../course-unit-teaching.service';
import {
    SelectCourseUnitRealisationComponent,
} from '../select-course-unit-realisation/select-course-unit-realisation.component';

export interface SelectCourseUnitRealisationResult {
    selectedCourseUnitRealisation?: CourseUnitRealisation,
    enrolment?: Enrolment
}
@Component({
    selector: 'app-degree-studies-untimed',
    templateUrl: './degree-studies-untimed.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DegreeStudiesUntimedComponent implements OnChanges {
    @Input() validatablePlan: ValidatablePlan;
    @Input() enrolmentsInCalendar: Enrolment[];

    loading$ = new BehaviorSubject<boolean>(false);

    studyPeriods: { [key: string]: StudyPeriod };
    studyYearsByStartYear: { [key: number]: StudyYear };

    readonly defaultPeriodCount = 2;
    readonly defaultCourseUnitCount = 6;

    untimedCourseUnits: CourseUnit[] = [];
    courseUnitsWithPeriods: { [key: string]: CourseUnit[] };
    gradeRaiseCourseUnits: CourseUnit[] = [];

    courseUnitTeachings: { [key: string]: CourseUnitTeaching };
    courseUnitTeachingForGradeRaiseAttempts: { [key: string]: CourseUnitTeaching };

    visibleCourseUnitsWithPeriods: { [key: string]: CourseUnit[] };
    visibleUntimedCourseUnits: CourseUnit[] = [];

    totalPeriodCount: number;
    visiblePeriodCount: number;

    private openEnrolmentModal = enrolmentWizardModalOpener();
    private continueToEnrolmentModalOpener = continueToEnrolmentModalOpener();

    constructor(private stateService: StateService,
                private universityService: UniversityService,
                private errorHandler: AppErrorHandler,
                private localeService: LocaleService,
                private modalService: ModalService,
                private courseUnitTeachingService: CourseUnitTeachingService,
                private courseUnitEntityService: CourseUnitEntityService,
                private appErrorHandler: AppErrorHandler,
                @Inject(COMMON_STUDY_PERIOD_SERVICE) private commonStudyPeriodService: any,
                @Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any) {
    }

    ngOnChanges() {
        this.loading$.next(true);

        this.courseUnitsWithPeriods = undefined;
        this.visibleCourseUnitsWithPeriods = undefined;
        this.totalPeriodCount = undefined;
        this.visiblePeriodCount = undefined;

        this.visibleUntimedCourseUnits = [];
        this.untimedCourseUnits = [];
        if (this.validatablePlan) {
            this.getUnAttainedCourseUnitsFromPlan(this.validatablePlan)
                .pipe(
                    concatMap((res) => this.getGradeRaiseAttemptCourseUnits(this.validatablePlan)),
                    concatMap((res) => this.setCourseUnitTeachingsForGradeRaiseAttempt()),
                    concatMap((res) => this.getPeriods()),
                    concatMap((res) => this.setStudyYearsByStartYear()),
                    this.errorHandler.defaultErrorHandler(),
                    defaultIfEmpty(undefined),
                ).subscribe(() => {
                    this.loading$.next(false);
                });
        } else if (this.validatablePlan === null) {
            this.loading$.next(false);
        }
    }

    showSelectCourseUnitRealisationModal(courseUnit: CourseUnit) {
        const gradeRaiseAttempt = this.gradeRaiseAttemptForCourseUnit(courseUnit.id);
        if (gradeRaiseAttempt) {
            const foundCourseUnit = this.gradeRaiseCourseUnits.find(cu => cu.id === gradeRaiseAttempt.courseUnitId);
            const completionMethod = foundCourseUnit.completionMethods.find(({ localId }) => localId === gradeRaiseAttempt.completionMethodId);

            const assessmentItemIds: OtmId[] = completionMethod.assessmentItemIds.filter(assessmentItemId => _.includes(gradeRaiseAttempt.selectedAssessmentItemIds, assessmentItemId));
            this.openSelectCourseUnitRealisationModal(foundCourseUnit, completionMethod, assessmentItemIds);
        } else {
            const completionMethod = this.validatablePlan.getSelectedCompletionMethod(courseUnit);
            const selectedAssessmentItemIds = this.validatablePlan.getSelectedAssessmentItems(courseUnit).map(ai => ai.id);
            const assessmentItemIds: OtmId[] = completionMethod.assessmentItemIds.filter(assessmentItemId => _.includes(selectedAssessmentItemIds, assessmentItemId));

            this.openSelectCourseUnitRealisationModal(courseUnit, completionMethod, assessmentItemIds);
        }
    }

    private openSelectCourseUnitRealisationModal(courseUnit: CourseUnit, completionMethod: CompletionMethod, assessmentItemIds: OtmId[]) {
        this.modalService.open(SelectCourseUnitRealisationComponent, {
            courseUnit,
            completionMethod,
            assessmentItemIds,
        }, { size: 'md' }).closed
            .subscribe((selectCourseUnitRealisationResult: SelectCourseUnitRealisationResult) => {
                this.handleSelectCourseUnitRealisationResult(selectCourseUnitRealisationResult);
            });
    }

    getGradeRaiseAttemptCourseUnits(validatablePlan: ValidatablePlan) {
        const gradeAttemptCourseUnitIds = this.getGradeRaiseAttemptCourseUnitIds(validatablePlan);
        return this.getCourseUnits(gradeAttemptCourseUnitIds).pipe(tap(courseUnits => this.gradeRaiseCourseUnits = courseUnits));
    }

    private getGradeRaiseAttemptCourseUnitIds(validatablePlan: ValidatablePlan) {
        return validatablePlan.getAllCourseUnitsInPlan()
            .map(cu => validatablePlan.getCourseUnitSelection(cu.id))
            .filter(cus => (_.get(cus, 'gradeRaiseAttempt') && _.get(cus, 'attainment')))
            .map((cus: CourseUnitSelection) => cus.gradeRaiseAttempt.courseUnitId);
    }

    private getCourseUnits(courseUnitIds: OtmId[]): Observable<CourseUnit[]> {
        if (courseUnitIds.length === 0) return of([]);

        return this.courseUnitEntityService.getByIds(courseUnitIds)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler());
    }

    handleSelectCourseUnitRealisationResult(selectCourseUnitRealisationResult: SelectCourseUnitRealisationResult) {
        if (selectCourseUnitRealisationResult) {
            this.continueToEnrolmentModalOpener(selectCourseUnitRealisationResult.selectedCourseUnitRealisation)
                .afterClosed()
                .subscribe((shouldOpenEnrolmentModal) => {
                    if (shouldOpenEnrolmentModal) {
                        this.openEnrolmentModal({ enrolment: selectCourseUnitRealisationResult.enrolment, isUpdate: false, isConfirmedSsgEdit: false });
                    }
                });
        }
    }

    showCourseUnitModal(courseUnitId: OtmId) {
        from(this.courseUnitInfoModal.showCourseUnit(courseUnitId, this.validatablePlan));
    }

    groupByPlannedPeriods(courseUnits: CourseUnit[]): { [key: string]: CourseUnit[] } {
        const courseUnitsByPeriods = _.groupBy(courseUnits, courseUnit =>
            this.validatablePlan ? _.head(this.validatablePlan.getPlannedPeriods(courseUnit)) : undefined);

        const courseUnitsByPeriodsSorted: { [key: string]: CourseUnit[] } = {};

        _.chain(courseUnitsByPeriods)
            .keys()
            .sortBy()
            .forEach((key) => courseUnitsByPeriodsSorted[key] = courseUnitsByPeriods[key])
            .value();

        return courseUnitsByPeriodsSorted;
    }

    sortUntimedByName() {
        const currentLocale: string = this.localeService.getCurrentLanguage();
        this.untimedCourseUnits = _.orderBy(this.untimedCourseUnits, [`name.${currentLocale}`], ['asc']);
    }

    mapCourseUnitResults(courseUnits: any): void {
        const allCourseUnits = courseUnits;
        if (_.has(allCourseUnits, 'undefined')) {
            this.untimedCourseUnits = allCourseUnits['undefined'];
            delete allCourseUnits['undefined'];
            this.sortUntimedByName();
        }

        this.totalPeriodCount = _.keys(allCourseUnits).length;
        this.courseUnitsWithPeriods = allCourseUnits;

        this.setVisiblePeriodCourseUnits(true);
        this.setVisibleUntimedCourseUnits(true);
    }

    setVisibleUntimedCourseUnits(more: boolean): void {
        const value = this.getVisibleItemsCount(
            this.defaultCourseUnitCount,
            this.visibleUntimedCourseUnits.length,
            this.untimedCourseUnits.length,
            more);

        this.visibleUntimedCourseUnits = this.untimedCourseUnits.slice(0, value);
    }

    setVisiblePeriodCourseUnits(more: boolean): void {
        const value = this.getVisibleItemsCount(
            this.defaultPeriodCount,
            _.keys(this.visibleCourseUnitsWithPeriods).length,
            _.keys(this.courseUnitsWithPeriods).length,
            more);

        this.visibleCourseUnitsWithPeriods = Object.fromEntries(Object.entries(this.courseUnitsWithPeriods).slice(0, value));
        this.visiblePeriodCount = _.keys(this.visibleCourseUnitsWithPeriods).length;
    }

    getVisibleItemsCount(defaultValue: number, currentVisible: number, total: number, more: boolean): number {
        let count = more ? currentVisible + defaultValue : currentVisible - defaultValue;
        if (count < defaultValue) count = defaultValue;
        return total < count ? total : count;
    }

    getPeriods() {
        const studyPeriods$ = _.chain(this.courseUnitsWithPeriods)
            .keys()
            .compact()
            .uniq()
            .value()
            .map(periodId => from(convertAJSPromiseToNative<StudyPeriod>(this.commonStudyPeriodService.getPeriodsFor(periodId))));
        return forkJoin(studyPeriods$).pipe(
            this.errorHandler.defaultErrorHandler(),
            tap(studyPeriods => this.studyPeriods = _.keyBy(studyPeriods, 'locator')));
    }

    getUnAttainedCourseUnitsFromPlan(validatablePlan: ValidatablePlan) {
        const unAttainedCourseUnits = validatablePlan.getAllCourseUnitsInPlan()
            .filter(cu => {
                const isCourseUnitAttained = validatablePlan.isCourseUnitAttained(cu.id);

                return !isCourseUnitAttained || this.isGradeRaiseAttempt(validatablePlan, cu.id);
            });

        return this.courseUnitTeachingService.getCourseUnitTeachings(unAttainedCourseUnits, validatablePlan, this.enrolmentsInCalendar)
            .pipe(
                this.errorHandler.defaultErrorHandler(),
                tap(courseUnitsTeachings => {
                    this.courseUnitTeachings = keyBy(courseUnitsTeachings, 'courseUnitId');
                }),
                map(() => unAttainedCourseUnits),
                map(courseUnits => this.groupByPlannedPeriods(courseUnits)),
                tap(courseUnits => this.mapCourseUnitResults(courseUnits)),
            );
    }

    isGradeRaiseAttempt(validatablePlan: ValidatablePlan, courseUnitId: OtmId) {
        const courseUnitSelection = validatablePlan.getCourseUnitSelection(courseUnitId);
        const gradeRaiseAttempt = _.get(courseUnitSelection, 'gradeRaiseAttempt') as GradeRaiseAttempt;
        return !!gradeRaiseAttempt;
    }

    setCourseUnitTeachingsForGradeRaiseAttempt() {
        const gradeRaiseAttempts = this.validatablePlan.getAllCourseUnitsInPlan().map(cu => {
            const courseUnitSelection = this.validatablePlan.getCourseUnitSelection(cu.id) as CourseUnitSelection;
            return courseUnitSelection.gradeRaiseAttempt;
        }).filter(Boolean);

        return this.courseUnitTeachingService.getCourseUnitTeachingForGradeRaiseAttempt(this.gradeRaiseCourseUnits, this.validatablePlan, this.enrolmentsInCalendar, gradeRaiseAttempts)
            .pipe(
                this.errorHandler.defaultErrorHandler(),
                tap(courseUnitsTeachings => {
                    this.courseUnitTeachingForGradeRaiseAttempts = keyBy(courseUnitsTeachings, 'courseUnitId');
                }),
            );
    }

    navigateToPlanStructure() {
        if (this.validatablePlan) {
            this.stateService.go('student.logged-in.structure', { planId: this.validatablePlan.plan.id });
        } else {
            this.stateService.go('student.logged-in.planSelect');
        }
    }

    setStudyYearsByStartYear() {
        const parsedLocators = _.chain(_.keys(this.courseUnitsWithPeriods))
            .uniq()
            .map(this.commonStudyPeriodService.parseStudyPeriodLocator)
            .value();

        return of(parsedLocators).pipe(
            switchMap((res: any) => {
                const organisationId = this.universityService.getCurrentUniversityOrgId();
                const years = _.map(res, 'year');
                const firstYear = _.min(years);
                const numOfYears = _.max(years) - firstYear + 1;

                return from(convertAJSPromiseToNative<StudyYear[]>(this.commonStudyPeriodService.getStudyYears(organisationId, firstYear, numOfYears)));
            }),
            this.errorHandler.defaultErrorHandler(),
            tap(studyYears => this.studyYearsByStartYear = _.keyBy(studyYears, 'startYear')),
        );
    }

    getStudyPeriodNameByPeriodId(periodId: any) {
        return _.get(this.studyPeriods, `[${periodId}].name`);
    }

    hasCompletionMethodSelected(courseUnit: CourseUnit) {
        const courseUnitSelection = this.validatablePlan.getCourseUnitSelection(courseUnit.id) as CourseUnitSelection;
        const selectedMethod = this.validatablePlan?.getSelectedCompletionMethod(courseUnit);
        return !!selectedMethod || !!courseUnitSelection?.gradeRaiseAttempt?.completionMethodId;
    }

    hasSelectableAssessmentItems(courseUnit: CourseUnit): boolean {
        const courseUnitSelection = this.validatablePlan.getCourseUnitSelection(courseUnit.id) as CourseUnitSelection;
        if (courseUnitSelection.gradeRaiseAttempt) {
            return this.courseUnitTeachingForGradeRaiseAttempts?.[courseUnitSelection.gradeRaiseAttempt.courseUnitId]?.hasSelectableAssessmentItems;
        }
        return this.courseUnitTeachings?.[courseUnit.id]?.hasSelectableAssessmentItems;
    }

    hasAllAssessmentItemsSelected(courseUnit: CourseUnit): boolean {
        const courseUnitSelection = this.validatablePlan.getCourseUnitSelection(courseUnit.id) as CourseUnitSelection;
        if (courseUnitSelection.gradeRaiseAttempt) {
            return this.courseUnitTeachingForGradeRaiseAttempts?.[courseUnitSelection.gradeRaiseAttempt.courseUnitId]?.allAssessmentItemsSelected;
        }
        return this.courseUnitTeachings?.[courseUnit.id]?.allAssessmentItemsSelected;
    }

    getStudyYearNameByPeriodId(periodId: any) {
        const year = _.get(this.commonStudyPeriodService.parseStudyPeriodLocator(periodId), 'year');
        return _.get(this.studyYearsByStartYear, `[${year}].name`);
    }

    private gradeRaiseAttemptForCourseUnit(courseUnitId: OtmId): GradeRaiseAttempt {
        const courseUnitSelection = this.validatablePlan.getCourseUnitSelection(courseUnitId);
        return _.get(courseUnitSelection, 'gradeRaiseAttempt');
    }
}
